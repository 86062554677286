import './App.css';
import './sticky-footer-navbar.css';

import { Auth } from 'aws-amplify';
import i18n from 'i18next';
import React, { Suspense, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { BoxArrowInRight, GearWideConnected } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/esm/Container';
import Modal from 'react-bootstrap/Modal';
import Navbar from 'react-bootstrap/Navbar';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';

import FadLoading from './components/FadLoading';
import { LOCAL_STORAGE_I18_LANG } from './libs/constants';
import { AppContext } from './libs/contextLib';
import logDebug from './libs/debug';
import { localStorageSet } from './libs/localStorageUtils';
import logo from './logo.svg';
import Routes from './Routes';

function App() {
  const { t } = useTranslation();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isFluidContainer, setIsFluidContainer] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [language, setLanguage] = useState("EN");
  const [userEmail, setUserEmail] = useState("");
  const [showLanguageSettings, setShowLanguageSettings] = useState(false);

  useEffect(() => {
    const onLoad = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user && user.attributes && user.attributes.email) {
          userHasAuthenticated(true);
          logDebug(user);
          setUserEmail(user.attributes.email);
        }
      }
      catch(e) {
        if (e !== "No current user") {
          logDebug("Auth.currentSession exception ", e);
        }
      }
      setIsAuthenticating(false);
    };

    onLoad();
    setLanguage(i18n.language.toUpperCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showLanguageSettings) {
      const radios = document.getElementsByName("langsettings");
      for (let i = 0, length = radios.length; i < length; i++) {
        const radioInput = radios[i] as HTMLInputElement;
        if (radioInput && radioInput.value == i18n.language) {
          radioInput.checked = true
          break;
        }
      }
    }
}, [showLanguageSettings]);

  function handleLanguageSettings(event: any) {
    event.preventDefault();
    setShowLanguageSettings(true);
  }

  function handleLanguageCancel(event: any) {
    event.preventDefault();
    setShowLanguageSettings(false);
  }

  function handleLanguageConfirm(event: any) {
    event.preventDefault();
    const radios = document.getElementsByName("langsettings");
    for (let i = 0, length = radios.length; i < length; i++) {
      const radioInput = radios[i] as HTMLInputElement;
      if (radioInput && radioInput.checked) {
        const newLang = radioInput.value;
        i18n.changeLanguage(newLang)
        .then((t) => {
          console.log("Language changed");
          localStorageSet(LOCAL_STORAGE_I18_LANG, newLang);
          setLanguage(i18n.language.toUpperCase());
        });
        break;
      }
    }
    setShowLanguageSettings(false);
  }
  
  return <Suspense fallback={<FadLoading />}>
    {isAuthenticating ? <FadLoading /> : (
      <Router>
        <Navbar collapseOnSelect bg="fadPrimary" expand={true} className={isFluidContainer ? "mb-0" : "mb-3"} variant="dark">
          <LinkContainer to="/home">
            <Navbar.Brand className="font-weight-bold" id="fadBrand">
              <img src={logo} width="32" height="32" className="d-inline-block align-top mr-2" alt="logo" loading="lazy" />
              français all day
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Button as="button" className="btn my-0 py-0 ml-auto text-light" variant="" onClick={handleLanguageSettings}>
              {language}
            </Button>
            {isAuthenticated ? <LinkContainer to="/settings">
                <Button as="button" className="btn my-0 py-0" variant="">
                  <GearWideConnected className="fad-md-image-button text-light" />
                </Button>
              </LinkContainer> : <LinkContainer to="/auth">
              <Button as="button" className="btn my-0 py-0" variant="">
                <BoxArrowInRight className="fad-md-image-button text-light" />
              </Button>
            </LinkContainer>}
        </Navbar>
        <Container fluid={isFluidContainer} className={isFluidContainer ? "App p-0" : "App"}>
          <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, isFluidContainer, setIsFluidContainer, userEmail, setUserEmail }}>
            <Routes />
          </AppContext.Provider>
          <CookieConsent
            buttonText={t("accept")}
            cookieName="fadCookieConsent"
            style={{ background: "#e2e3e5", color: "#383d41" }}
            buttonStyle={{ color: "#ffffff", background: "#0a2647" }}
            expires={180}
            debug={false}
          >{t("cookieEplain")}</CookieConsent>
        </Container>
        <Modal animation={false} show={showLanguageSettings} centered onHide={() => setShowLanguageSettings(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{t("languageSettings")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row noGutters>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-en" name="langsettings" value="en"/>
                &nbsp;English
              </Col>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-de" name="langsettings" value="de"/>
                &nbsp;German Deutsche
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-es" name="langsettings" value="es"/>
                &nbsp;Spanish Español
              </Col>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-id" name="langsettings" value="id"/>
                &nbsp;Indonesian Bahasa Indonesia
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-it" name="langsettings" value="it"/>
                &nbsp;Italian Italiano
              </Col>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-ja" name="langsettings" value="ja"/>
                &nbsp;Japanese 日本語
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-ko" name="langsettings" value="ko"/>
                &nbsp;Korean 한국어
              </Col>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-ms" name="langsettings" value="ms"/>
                &nbsp;Malay Bahasa Melayu
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-nl" name="langsettings" value="nl"/>
                &nbsp;Dutch Nederlands
              </Col>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-pt" name="langsettings" value="pt"/>
                &nbsp;Portuguese Português
              </Col>
            </Row>
            <Row noGutters>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-vi" name="langsettings" value="vi"/>
                &nbsp;Vietnamese Tiếng Việt
              </Col>
              <Col xs="12" sm="6">
                <input type="radio" id="lang-zh" name="langsettings" value="zh"/>
                &nbsp;Chinese 汉语
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleLanguageCancel}>
              {t("cancel")}
            </Button>
            <button className="btn btn-fadPrimary" type="button" onClick={handleLanguageConfirm}>
              {t("ok")}
            </button>
          </Modal.Footer>
        </Modal>
      </Router>
    )}</Suspense>;
}

export default App;
