import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useAppContext } from '../libs/contextLib';
import { querystring } from '../libs/utils';

interface UnauthenticatedRouteProps {
  exact?: boolean,
  path: string,
  component: any
}

export default function UnauthenticatedRoute({ component, ...rest }: UnauthenticatedRouteProps) {
  const { isAuthenticated } = useAppContext();
  const redirect = querystring("redirect");
  return (
    <Route {...rest}>
      {!isAuthenticated ? (
        React.createElement(component)
      ) : (
        <Redirect to={!redirect || redirect.length === 0 ? "/" : redirect} />
      )}
    </Route>
  );
}
