import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from './components/AsyncComponent';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

const AuthForm = asyncComponent(() => import("./containers/AuthForm"));
const ChangePassword = asyncComponent(() => import("./containers/ChangePassword"));
const Episode = asyncComponent(() => import("./containers/Episode"));
const Episodes = asyncComponent(() => import("./containers/Episodes"));
const Faq = asyncComponent(() => import("./containers/Faq"));
const ForgetPassword = asyncComponent(() => import("./containers/ForgetPassword"));
const Home = asyncComponent(() => import("./containers/Home"));
const More = asyncComponent(() => import("./containers/More"));
const NotFound = asyncComponent(() => import("./containers/NotFound"));
const Podcasts = asyncComponent(() => import("./containers/Podcasts"));
const Previous = asyncComponent(() => import("./containers/Previous"));
const Pricing = asyncComponent(() => import("./containers/Pricing"));
const Privacy = asyncComponent(() => import("./containers/Privacy"));
const Practice = asyncComponent(() => import("./containers/Practice"));
const Progress = asyncComponent(() => import("./containers/Progress"));
const Settings = asyncComponent(() => import("./containers/Settings"));
const Terms = asyncComponent(() => import("./containers/Terms"));
const Welcome = asyncComponent(() => import("./containers/Welcome"));

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/home" component={Home} />
      <Route exact path="/" component={Welcome} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/terms" component={Terms} />
      <Route exact path="/faq" component={Faq} />
      <Route exact path="/episode" component={Episode} />
      <Route exact path="/episodes" component={Episodes} />
      <Route exact path="/podcasts" component={Podcasts} />
      <Route exact path="/more" component={More} />
      <Route exact path="/previous" component={Previous} />
      <Route exact path="/pricing" component={Pricing} />
      <AuthenticatedRoute exact path="/progress" component={Progress} />
      <AuthenticatedRoute exact path="/practice" component={Practice} />
      <UnauthenticatedRoute exact path="/forget" component={ForgetPassword} />
      <AuthenticatedRoute exact path="/settings" component={Settings} />
      <UnauthenticatedRoute exact path="/auth" component={AuthForm} />
      <AuthenticatedRoute exact path="/changepw" component={ChangePassword} />
      <Route component={NotFound} />
    </Switch>
  );
}
