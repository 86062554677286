import './index.css';
import './localization/config';

import { Amplify } from 'aws-amplify';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';

import amplifyConfig from './amplifyConfig';
import App from './App';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: amplifyConfig.cognito.REGION,
    userPoolId: amplifyConfig.cognito.USER_POOL_ID,
    userPoolWebClientId: amplifyConfig.cognito.APP_CLIENT_ID
  }
});

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root')
);

