import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import deTranslation from './de/translation.json';
import enTranslation from './en/translation.json';
import esTranslation from './es/translation.json';
import idTranslation from './id/translation.json';
import itTranslation from './it/translation.json';
import jaTranslation from './ja/translation.json';
import koTranslation from './ko/translation.json';
import msTranslation from './ms/translation.json';
import nlTranslation from './nl/translation.json';
import ptTranslation from './pt/translation.json';
import viTranslation from './vi/translation.json';
import zhTranslation from './zh/translation.json';

export const resources = {
  en: {
    translation: enTranslation,
  },
  de: {
    translation: deTranslation,
  },
  es: {
    translation: esTranslation,
  },
  id: {
    translation: idTranslation,
  },
  it: {
    translation: itTranslation,
  },
  ja: {
    translation: jaTranslation,
  },
  ko: {
    translation: koTranslation,
  },
  ms: {
    translation: msTranslation,
  },
  nl: {
    translation: nlTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  vi: {
    translation: viTranslation,
  },
  zh: {
    translation: zhTranslation,
  },
} as const;

i18n.use(initReactI18next)
.use(LanguageDetector).init({
  supportedLngs: ["en", "de", "es", "id", "it", "ja", "ko", "ms", "nl", "pt", "vi", "zh"],
  fallbackLng: "en",
  resources,
});

export default i18n;