import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';

export interface FadLoadingProps {
  spinnerId?: string;
}

function FadLoading(props: FadLoadingProps) {
  return (
  <Row className="justify-content-xs-center">
    <Col xs>
    </Col>
    <Col xs="auto">
      <Spinner id={props.spinnerId ?? "fadLoading"} animation="border" className="fadPrimary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </Col>
    <Col xs>
    </Col>
  </Row>
  );
}

export default FadLoading;