
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { useAppContext } from '../libs/contextLib';

interface AuthenticatedRouteProps {
  exact?: boolean,
  path: string,
  component: any
}

export default function AuthenticatedRoute({ component, ...rest }: AuthenticatedRouteProps) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();
  const { t } = useTranslation();
  return (
    <Route {...rest}>
      {isAuthenticated ? (
        React.createElement(component)
      ) : (
        <Redirect to={{
          pathname: "/auth",
          search: `?redirect=${pathname}${search}`,
          state: { message: t("pleaseAuthenticateFirst")}
        }} />
      )}
    </Route>
  );
}
