export function logDebug(message?: any, ...optionalParams: any[]) {
  if (process.env.REACT_APP_DEBUG) {
    if (optionalParams) {
      console.log(message, ...optionalParams);
    } else {
      console.log(message);
    }
  }
}

export default logDebug;