import React, { createContext, useContext } from 'react';

type FADAppContext = {
  isAuthenticated: boolean;
  userHasAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  isFluidContainer: boolean;
  setIsFluidContainer: React.Dispatch<React.SetStateAction<boolean>>;
  userEmail: string;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
};
export const AppContext = createContext<FADAppContext>({
  isAuthenticated: false,
  userHasAuthenticated: (value) => {},
  isFluidContainer: false,
  setIsFluidContainer: (value) => {},
  userEmail: "",
  setUserEmail: (value) => { }
});

export function useAppContext() {
  return useContext(AppContext);
}
