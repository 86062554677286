export function getDurationSummary(seconds?: number): string {
  if (!seconds || seconds <= 0) {
    return "";
  }

  const hour = Math.floor(seconds / 3600.0);
  const min = Math.floor((seconds % 3600.0) / 60);
  if (hour === 0) {
    if (min === 0) {
      return "< 1 min";
    }

    return min + " min";
  }
  
  if (min < 10) {
    return hour + ":0" + min + " hour";
  }

  return hour + ":" + min + " hour";
}

export function getShortDateTime(secondsSinceAppleEpoch?: number): string {
  if (!secondsSinceAppleEpoch || secondsSinceAppleEpoch <= 0) {
    return "";
  }

  var d = new Date(1000 * (secondsSinceAppleEpoch + 978307200));
  return d.toDateString();
}

export function getTimestamp(second: number): string {
  if (second < 1) {
    return "00:00";
  }

  const minPart = (Math.floor(second / 60)) % 60;
  const secondPart = Math.round(second % 60);
  const hourPart = Math.floor(second / 3600);
  const hourString = hourPart > 0 ? hourPart + ":" : "";
  const minString = minPart < 10 ? "0" + minPart : "" + minPart;
  const secondString = secondPart < 10 ? "0" + secondPart : "" + secondPart;
  return hourString + minString + ":" + secondString;
}

export function querystring(name: string, url = window.location.href) {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getOS() {
  const userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export function isEmptyOrSpaces(strinput: any){
  return !strinput ||
    strinput === null ||
    !(strinput instanceof String || typeof(strinput) === "string") ||
    strinput.match(/^ *$/) !== null;
}

export default getDurationSummary;