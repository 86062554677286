export const DEFAULT_PAGE_TITLE = "français all day - ";
export const COOKIE_NAME_LAST_POST_ACCESS = "fadLastPostAccess";
export const COOKIE_NAME_PODCAST_TAP = "fadPodcastTap";
export const COOKIE_NAME_FORGET_PASSWORD_EMAIL = "fadForgetPasswordEmail";
export const COOKIE_NAME_TUTORIAL_TAP_WORD = "fadTutorialTapWord";
export const LOCAL_STORAGE_LAST_REFRESH = "fadRefresh";
export const LOCAL_STORAGE_I18_LANG = "i18nextLng";
export const PASSWORD_LENGTH = 6;
export const appleEpochDiffSeconds = 978307200;
export const SECOND_PER_DAY = 3600 * 24;
export const MAX_ITEM_PER_PAGE_RESULT = 25;
export const CHECKOUT_URL = process.env.REACT_APP_SUBSCRIPTION_API_ENDPOINT + "/account/stripecheckout";
export const DUE_WORD_COUNT_URL = process.env.REACT_APP_API_ENDPOINT + "/main/countdueword";
export const MORE_URL = process.env.REACT_APP_API_ENDPOINT + "/main/more";
export const PROGRESS_URL = process.env.REACT_APP_API_ENDPOINT + "/account/progress";
export const PREVIOUS_URL = process.env.REACT_APP_API_ENDPOINT + "/main/previous";