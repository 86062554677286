export function localStorageGet(key: string): string | null {
  if (localStorage) {
    return localStorage.getItem(key);
  }

  return null;
}

export function localStorageSet(key: string, value: string | null) {
  if (localStorage) {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }
}